<template>
    <div>
        <h4 class="font-weight-bolder mb-4">Permissions List</h4>
        <!-- <b-row> -->
        <b-card v-for="item in allPermissions" :key="item.name">
            <h3 class="text-primary font-weight-bolder">{{ item.title }}</h3>
            <b-row class="justify-content-end">
                <b-button @click="selectAllHandler(item)" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success">
                    <span class="align-middle">Select all / Unselect all</span>
                </b-button>
            </b-row>
            <b-row>
                <b-col cols="3" class="m-1" v-for="_item in item.sub_titles" :key="_item[0]">
                    <b-form-checkbox-group class="mt-50" v-for="__item in _item.permissions" :key="__item.id" v-model="selectedPermissions">
                        <b-form-checkbox :value="__item.id" :checked="indeterminate"> {{ __item.name }} </b-form-checkbox>
                    </b-form-checkbox-group>
                </b-col>
            </b-row>
        </b-card>
        <!-- </b-row> -->
        <b-row class="justify-content-center mt-3">
            <b-button variant="info pl-2" @click="submitPermissions">
                <span class="text-nowrap">Submit</span>
            </b-button>
        </b-row>
    </div>
</template>

<script>
import { BFormCheckboxGroup, BFormCheckbox, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useAccessLevel from "./useAccessLevelList";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const RANDOM_TOKEN = makeid(50);
import _ from "lodash";

export default {
    components: {
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormCheckbox,
        BFormCheckboxGroup,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        ToastificationContent,
    },
    directives: {
        Ripple,
    },
    setup(props, context) {
        const { addRole, fetchRoleList, showPermissions, fetchAllPermissions, perissionList, assignPermissions } = useAccessLevel();
        const roleId = router.currentRoute.params.id;
        const allPermissions = ref();
        const usersPermissions = ref();
        const selectedPermissions = ref([]);
        const indeterminate = ref(false);
        const checked = ref(true);
        fetchAllPermissions().then((res) => {
            allPermissions.value = res.value;
        });
        onMounted(() => {
            showPermissions(roleId).then((res) => {
                console.log();
                selectedPermissions.value = res.permissions.map((item) => {
                    return item.id;
                });
            });
        });

        const submitPermissions = () => {
            let data = { permissions: selectedPermissions.value };
            console.log(data);
            assignPermissions(data, roleId).then((res) => {
                if (res == false) {
                    context.root.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error",
                            icon: "BellIcon",
                            text: "Sorry! There is An Error...",
                            variant: "danger",
                        },
                    });
                } else {
                    context.root.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Updated",
                            icon: "BellIcon",
                            text: "Permissions Assigned Successfully👋",
                            variant: "success",
                        },
                    });
                }
            });
        };
        const selectAllHandler = (item) => {
            let permissionsParts = item.sub_titles.map((i) => {
                return i.permissions.map((j) => {
                    return j.id;
                });
            });
            let ids = [];
            permissionsParts.map((i) => {
                return ids.push(i);
            });
            let allExist = true;
            for (let i = 0; i < _.flatten(ids).length; i++) {
                if (!selectedPermissions.value.includes(_.flatten(ids)[i])) {
                    allExist = false;
                    break;
                }
            }
            console.log(allExist);
            if (allExist) {
                for (let i = 0; i < _.flatten(ids).length; i++) {
                    const index = selectedPermissions.value.indexOf(_.flatten(ids)[i]);
                    if (index > -1) {
                        selectedPermissions.value.splice(index, 1);
                    }
                }
            } else {
                for (let i = 0; i < _.flatten(ids).length; i++) {
                    if (!selectedPermissions.value.includes(_.flatten(ids)[i])) {
                        selectedPermissions.value.push(_.flatten(ids)[i]);
                    }
                }
            }

            console.log(selectedPermissions.value);
        };
        return {
            allPermissions,
            usersPermissions,
            submitPermissions,
            selectedPermissions,
            selectAllHandler,
            indeterminate,
            checked,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
